import TextField from "@material-ui/core/TextField";
function stepSeven({ info, change }) {
  return (
    <div className="add-card">
      <center>Proposed Investment Breakdown</center>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Year One"
            className="input-50"
            size="small"
            value={info[6].yearOne}
            onChange={(e) => change(6, e.target.value, "yearOne")}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Year Two"
            className="input-50"
            size="small"
            value={info[6].yearTwo}
            onChange={(e) => change(6, e.target.value, "yearTwo")}
          />
        </div>
      </div>

      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Year Three"
            className="input-50"
            size="small"
            value={info[6].yearThree}
            onChange={(e) => change(6, e.target.value, "yearThree")}
          />
        </div>
      </div>
      <div style={{ textAlign: "center", paddingTop: "20px" }}>
        <h3>Total:</h3>
        {parseInt(info[6].yearOne) +
          parseInt(info[6].yearTwo) +
          parseInt(info[6].yearThree)}
      </div>
    </div>
  );
}

export default stepSeven;
