import TextField from "@material-ui/core/TextField";
function stepEight({ info, change }) {
  return (
    <div className="add-card">
      <center>Employee Details</center>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Number of employees"
            className="input-50"
            size="small"
            value={info[7].numberOfEmployees}
            onChange={(e) => change(7, e.target.value, "numberOfEmployees")}
          />
        </div>
      </div>
      <div style={{ paddingTop: 10, fontSize: 14 }}>
        <center>Local Employees</center>
      </div>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Managerial"
            className="input-50"
            size="small"
            value={info[7].local.managerial}
            onChange={(e) => change(7, e.target.value, "local", "managerial")}
          />
        </div>
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Skilled"
            className="input-50"
            size="small"
            value={info[7].local.skilled}
            onChange={(e) => change(7, e.target.value, "local", "skilled")}
          />
        </div>
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Unskilled"
            className="input-50"
            size="small"
            value={info[7].local.unskilled}
            onChange={(e) => change(7, e.target.value, "local", "unskilled")}
          />
        </div>
      </div>
      <div style={{ paddingTop: 10, fontSize: 14 }}>
        <center>Foreign Employees</center>
      </div>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Managerial"
            className="input-50"
            size="small"
            value={info[7].foreign.managerial}
            onChange={(e) => change(7, e.target.value, "foreign", "managerial")}
          />
        </div>
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Skilled"
            className="input-50"
            size="small"
            value={info[7].foreign.skilled}
            onChange={(e) => change(7, e.target.value, "foreign", "skilled")}
          />
        </div>
      </div>
    </div>
  );
}

export default stepEight;
