import {
  BrowserRouter as Router,
  useParams,
  Link,
  useHistory,
} from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { viewLicense } from "./../Utils/API_fetching";
import BreadCrumbs from "./BreadCrumbs";
import Loading from "./Loading";
import Button from "@material-ui/core/Button";
import { MdPrint, MdAutorenew } from "react-icons/md";
import { ComponentToPrint } from "./Print";
import { toast } from "react-toastify";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { renewLicense } from "../Utils/API_fetching";
import { USER_ROLE, APPROVAL_ROLE } from "../Utils/UserRoles";
import { UserContext } from "../Hooks/UserContext";

import { addDays, addMonths, addYears } from "date-fns";

import { Grid, TextField } from "@material-ui/core";
import { object } from "joi";
function License() {
  const { user, setUser } = useContext(UserContext);
  let { slug, license } = useParams();
  let history = useHistory();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [issueDate, setIssueDate] = useState(new Date());
  const [expireDate, setExpireDate] = useState(addYears(new Date(), 1));
  useEffect(() => {
    viewLicense(slug, license)
      .then((res) => {
        setData(res.data.license[0]);
        // console.log(res.data.license[0]);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.response);
        window.setTimeout(() => {
          history.push("/list");
        }, 1500);
      });
  }, []);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  async function renewal() {
    try {
      const body = { issueDate, expireDate };
      renewLicense(slug, license, body).then((res) =>
        toast.success("License Renewed")
      );
    } catch (e) {
      toast.error("Oops error trying to renew. License please try again");
    }
  }
  return (
    <>
      <BreadCrumbs current={"Company License"} />
      {loading === true ? <Loading /> : renderLicense()} {modal && modalDiv()}
    </>
  );

  function renderLicense() {
    return (
      <div className="license" id="print">
        <div className="flex license-buttons">
          <div>
            {/* USER AND APPROVAL CAN NOT RENEW LICENSE */}
            {user.role !== USER_ROLE && user.role !== APPROVAL_ROLE && (
              <>
                {new Date().toISOString() > data.license.expireAt && (
                  <Button className="red" onClick={() => setModal(true)}>
                    <MdAutorenew />
                  </Button>
                )}
              </>
            )}
          </div>
          <div>
            <Button className="print" onClick={handlePrint}>
              <MdPrint />
            </Button>
          </div>
        </div>

        <ComponentToPrint ref={componentRef} data={data} />
      </div>
    );
  }

  function modalDiv() {
    const handleDateChange = (date) => {
      setIssueDate(new Date(date));
      setExpireDate(addYears(new Date(date), 1));
    };
    return (
      <div className="info-modal">
        {/* <div className="info-modal" onClick={() => setModal(!modal)}> */}
        <div className="info-modal-card">
          <h4>License Renewal</h4>
          <br />
          <p>
            Are you sure you want to renew a license for{" "}
            <b>{data.companyName}</b>?
          </p>
          <b />
          <div style={{ paddingTop: 20, gap: 10 }}>
            <div>
              <TextField
                id="date"
                label="Rewnewed Date"
                type="date"
                defaultValue={issueDate.toISOString().split("T")[0]}
                style={{ width: 300 }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleDateChange(e.target.value)}
              />
            </div>
            <div style={{ paddingTop: 20 }}>
              <TextField
                id="date"
                label="Expire Date"
                type="date"
                defaultValue={expireDate.toISOString().split("T")[0]}
                value={expireDate.toISOString().split("T")[0]}
                disabled={true}
                style={{ width: 300 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
          <div style={{ paddingTop: 20 }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 10 }}
              onClick={renewal}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setModal(!modal)}
            >
              No
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default License;
