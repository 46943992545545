import BreadCrumbs from "./BreadCrumbs";
import Search from "./Search";
import Pagination from "./Pagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { toast } from "react-toastify";
import {
  MdDone,
  MdClose,
  MdVisibility,
  MdBlock,
  MdBorderColor,
} from "react-icons/md";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import { getCompanies } from "./../Utils/API_fetching";
import { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactPaginate from "react-paginate";

function List() {
  const [search, setSearch] = useState("");
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [paging, setPaging] = useState({
    limit: 8,
    page: 1,
    pages: 1,
    skip: 0,
  });
  let history = useHistory();

  function find(value) {
    setSearch(value);
    setCurrentPage(1);
  }

  const handlePageClick = (data) => {
    let selected = data.selected;
    console.log(selected);
    setCurrentPage(selected + 1);
  };
  async function fetchData() {
    try {
      const data = await getCompanies(search, currentPage);
      setCompanies(data.data.companies);
      setPaging(data.data.paging);
      setLoading(false);
    } catch (e) {
      toast.error(e.response.data.message);
      history.push("/");
    }
  }

  useEffect(() => {
    fetchData();
  }, [search, currentPage]);

  return (
    <div>
      <BreadCrumbs current={"Company List"} />
      <Search find={find} />
      <TableContainer component={Paper} className="list-table">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell align="right">Complete Registration</TableCell>
              <TableCell align="right">Investment Sector</TableCell>
              <TableCell align="right">Approved</TableCell>
              <TableCell align="right">Certificate</TableCell>
              <TableCell align="right">View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell
                  colspan="5"
                  component="th"
                  scope="row"
                  style={{ padding: "30px", textAlign: "center" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {companies.length === 0 && loading === false && (
              <TableRow>
                <TableCell
                  colspan="6"
                  component="th"
                  scope="row"
                  style={{
                    padding: "30px",
                    textAlign: "center",
                  }}
                >
                  <h4>⛔ Company you searched for doesn't exist</h4>
                </TableCell>
              </TableRow>
            )}
            {companies.map((company) => (
              <TableRow key={company._id}>
                <TableCell component="th" scope="row">
                  {company.companyName}
                </TableCell>
                <TableCell align="right">
                  {company.details === undefined && company.approved === true && (
                    <Link to={`/registration/${company.slug}`}>
                      <button className="btn-complete">
                        Complete Registration
                      </button>
                    </Link>
                  )}
                  {company.details === undefined &&
                    company.approved === false && (
                      <button className="btn-danger">Not Approved</button>
                    )}
                  {company.details !== undefined &&
                    company.approved === true && (
                      <MdDone color={"green"} size={20} />
                    )}
                </TableCell>
                <TableCell align="right">{company.investmentSector}</TableCell>
                <TableCell align="right">
                  {company.approved ? (
                    <MdDone color={"green"} size={20} />
                  ) : (
                    <MdClose color={"red"} size={20} />
                  )}
                </TableCell>
                <TableCell align="right">
                  {company.license === undefined ? (
                    <MdClose color={"red"} size={20} />
                  ) : (
                    <MdDone color={"green"} size={20} />
                  )}
                </TableCell>
                <TableCell align="right">
                  <Link to={`/view/${company.slug}`} className="link">
                    <MdVisibility size={20} />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {paging.pages > 1 && (
        <ReactPaginate
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={paging.pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      )}
      {/* {paging.pages > 1 && (
        <Pagination paging={paging} changePage={changePage} />
      )} */}
    </div>
  );
}

export default List;
