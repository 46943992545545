import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";

function BreadCrumbs({ current }) {
  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: "30px" }}>
      <Link to="/" className="link">
        Home
      </Link>
      <Typography color="textPrimary">{current}</Typography>
    </Breadcrumbs>
  );
}

export default BreadCrumbs;
