import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
function stepTwo({ change, info }) {
  return (
    <div className="add-card">
      <center>Tpye of Business</center>

      <div className="flex text-area">
        <div className="text-100">
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">
              Type of business
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={info[1].typeOfBusiness}
              onChange={(e) => change(1, e.target.value, "typeOfBusiness")}
              className="input-100"
              style={{ width: "100%" }}
            >
              <MenuItem value={"Foreign Solo Proprietorship"}>
                Foreign Solo Proprietorship
              </MenuItem>
              <MenuItem value={"Local Solo Proprietorship"}>
                Local Solo Proprietorship
              </MenuItem>
              <MenuItem value={"Foreign Partnership"}>
                Foreign Partnership
              </MenuItem>
              <MenuItem value={"Local Partnership"}>Local Partnership</MenuItem>
              <MenuItem value={"Joint Venture"}>Joint Venture </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default stepTwo;
