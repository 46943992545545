import BreadCrumbs from "./BreadCrumbs";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { addCompany, findCompany, editCompany } from "../Utils/API_fetching";
import { useHistory, useParams } from "react-router-dom";
import Loading from "./Loading";
import { companyValidation } from "../Utils/validation";
import CategoryDropdown from "../Utils/CategoryDropdown";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";

function Add({ edit }) {
  const [data, setData] = useState({
    companyName: "",
    investmentOrigin: "",
    investmentSector: "",
    commencementDate: "",
    localAddress: "",
    headQuaters: "",
    // existing: "",
    phone: "",
    email: "",
    tell: "",
    website: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  let { slug } = useParams();
  useEffect(() => {
    if (edit !== undefined && edit === "edit") {
      findCompany(slug)
        .then((res) => {
          res.data.company.commencementDate =
            res.data.company.commencementDate.split("T")[0];
          setData(res.data.company);
          setLoading(false);
        })
        .catch((e) => {
          toast.error(`${e.response.data.message}`);
          window.setTimeout(() => {
            history.push("/list");
          }, 1500);
        });
    }
  }, []);

  async function add() {
    try {
      if (submitted === true) return;

      const res = await companyValidation(data);

      if (res === false) {
        const res = await addCompany(data);
        toast.success("Company has been created");
        window.setTimeout(() => {
          history.push(`/view/${res.data.slug}`);
        }, 1500);
      }
      setSubmitted(false);
    } catch (e) {
      setSubmitted(false);
      toast.error(`⚠ ${e.response.data.message}`);
    }
  }

  async function editCompanyInfo() {
    try {
      const res = await companyValidation(data);
      if (res === false) {
        const res = await editCompany(slug, data);
        toast.success("Company edited");
        window.setTimeout(() => {
          history.push(`/view/${res.data.slug}`);
        }, 1500);
      }
    } catch (e) {
      toast.error(`⚠ ${e.response.data.message}`);
    }
  }
  return (
    <>
      {edit !== undefined && edit === "edit" ? (
        <BreadCrumbs current={"Edit Company"} />
      ) : (
        <BreadCrumbs current={"Add Company"} />
      )}

      {edit !== undefined && edit === "edit" && loading === true ? (
        <Loading />
      ) : (
        form()
      )}
    </>
  );

  function form() {
    return (
      <div className="add-card">
        <center>Register Company</center>
        <div className="flex text-area">
          <div className="text-100">
            <TextField
              id="standard-basic"
              label="Company Name"
              className="input-50"
              size="small"
              value={data.companyName}
              onChange={(e) =>
                setData({ ...data, companyName: e.target.value })
              }
            />
          </div>
        </div>

        <div className="flex text-area">
          <div className="text-50">
            <TextField
              id="standard-basic"
              label="Investment Origin"
              className="input-50"
              size="small"
              value={data.investmentOrigin}
              onChange={(e) =>
                setData({ ...data, investmentOrigin: e.target.value })
              }
            />
          </div>

          <div className="text-50">
            <CategoryDropdown
              value={data.investmentSector}
              set={setData}
              data={data}
            />
          </div>
        </div>

        <div className="flex text-area">
          <div className="text-50">
            <TextField
              id="date"
              label="Commencement Date"
              type="date"
              className="input-50"
              InputLabelProps={{
                shrink: true,
              }}
              value={data.commencementDate}
              onChange={(e) =>
                setData({ ...data, commencementDate: e.target.value })
              }
            />
          </div>
          <div className="text-50">
            <TextField
              id="standard-basic"
              label="Local Address"
              className="input-50"
              size="small"
              value={data.localAddress}
              onChange={(e) =>
                setData({ ...data, localAddress: e.target.value })
              }
            />
          </div>
        </div>
        <div className="flex text-area">
          <div className="text-50">
            <TextField
              id="standard-basic"
              label="HeadQuaters"
              className="input-50"
              size="small"
              value={data.headQuaters}
              onChange={(e) =>
                setData({ ...data, headQuaters: e.target.value })
              }
            />
          </div>
          <div className="text-50">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Renewal Company
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  className="input-50"
                  value={data.existing}
                  onChange={(e) =>
                    setData({ ...data, existing: e.target.value })
                  }
                >
                  <MenuItem value="true">Renewal</MenuItem>
                  <MenuItem value="false">New</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div className="flex text-area">
          <div className="text-50">
            <TextField
              id="standard-basic"
              label="Phone"
              className="input-50"
              size="small"
              value={data.phone}
              onChange={(e) => setData({ ...data, phone: e.target.value })}
            />
          </div>
          <div className="text-50">
            <TextField
              id="standard-basic"
              label="Email"
              className="input-50"
              size="small"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
          </div>
        </div>
        <div className="flex text-area">
          <div className="text-50">
            <TextField
              id="standard-basic"
              label="Tell"
              className="input-50"
              size="small"
              // type="number"
              value={data.tell}
              onChange={(e) => {
                setData({ ...data, tell: e.target.value });
              }}
            />
          </div>
          <div className="text-50">
            <TextField
              id="standard-basic"
              label="Website"
              className="input-50"
              size="small"
              value={data.website}
              onChange={(e) => setData({ ...data, website: e.target.value })}
            />
          </div>
        </div>
        <div className="submission">
          {edit !== undefined && edit === "edit" ? (
            <Button
              variant="contained"
              className="btn-submit"
              onClick={() => editCompanyInfo()}
            >
              Edit
            </Button>
          ) : (
            <Button
              variant="contained"
              className="btn-submit"
              onClick={() => {
                add();
                setSubmitted(true);
              }}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default Add;
