import CircularProgress from "@material-ui/core/CircularProgress";

function Loading() {
  return (
    <div className="loading">
      <CircularProgress />

      <p>Bare with us we are getting your data.....</p>
    </div>
  );
}

export default Loading;
