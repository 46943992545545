import React from "react";

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <div>
        {this.props.data.existing ? <RenewLicense /> : <NewLicense />}
        <h2 className="license-company">{this.props.data.companyName}</h2>
        <h2 className="license-sector">{this.props.data.investmentSector}</h2>
        <h2 className="license-chairman">
          {this.props.data.shareholder[0].sectionFive[0].fullName}
        </h2>
        <h2 className="license-date">
          {this.props.data.commencementDate.split("T")[0]}
        </h2>
        <h2 className="license-no">{this.props.data.license.licenseNo}</h2>
        <h2 className="license-valid">
          {this.props.data.license.dateIssued.split("T")[0]}
        </h2>
        <h2 className="license-expires">
          {this.props.data.license.expireAt.split("T")[0]}
        </h2>
      </div>
    );
  }
}

function NewLicense() {
  return (
    <img
      style={{ width: " 100%", height: "100vh" }}
      src="/new.jpg"
      alt="loading..."
    />
  );
}

function RenewLicense() {
  return (
    <img
      style={{ width: " 100%", height: "100vh" }}
      src="/renew.jpg"
      alt="loading..."
    />
  );
}
