export const structure = [
  { country: "", address: "", date: "", number: "" },
  { typeOfBusiness: "" },
  {
    fullName: "",
    nationality: "",
    legalDocument: "",
    number: "",
    citizenship: "",
    dateOfBirth: "",
    address: "",
    tell: "",
  },
  [
    {
      fullName: "",
      position: "",
      shareholder: "",
      legalDocument: "",
      number: "",
      dateOfBirth: "",
      citizenship: "",
      address: "",
      tell: "",
    },
  ],
  {
    investmentValue: 0,
    startingCapital: 0,
    debt: 0,
    equityAmount: 0,
    equity: 0,
    preferenceShares: 0,
  },
  {
    land: 0,
    plant: 0,
    workingCapital: 0,
  },
  {
    yearOne: 0,
    yearTwo: 0,
    yearThree: 0,
  },
  {
    numberOfEmployees: 0,
    local: { managerial: 0, skilled: 0, unskilled: 0 },
    foreign: { managerial: 0, skilled: 0 },
  },
  {
    position: "",
    name: "",
    nationality: "",
    legalDocument: "",
    dateOfBirth: "",
    address: "",
    phone: "",
    email: "",
  },
  {
    projectProposal: "",
    companyLicense: "",
    companyAgreement: "",
  },
];
