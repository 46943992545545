import { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import Header from "./components/Partials/Header";
import Footer from "./components/Partials/Footer";
import Login from "./components/Login";
import Main from "./components/Main";
import Add from "./components/Add";
import List from "./components/List";
import Logs from "./components/Logs";
import Settings from "./components/Settings/Settings";
import View from "./components/View";
import Approve from "./components/Approve";
import Registration from "./components/Registeration/Registration";
import License from "./components/License";
import Report from "./components/Report";
import NotFound from "./components/NotFound";
import PrivateRoute from "./PrivateRoute";
import LoggedRoute from "./LoggedRoute";
import Category from "./components/Category";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "./Hooks/UserContext";
import { getUser } from "./Utils/API_fetching";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  async function get() {
    const res = await getUser();
    setUser(res.data.user);
    setLoading(false);
  }
  //TODO: redirect to login page

  useEffect(() => {
    try {
      get();
    } catch {
      window.location.href = `https://www.moiidlicense.com/login`;
    }
    // get().catch((e) => {
    //   window.location.href = `https://www.moiidlicense.com/login`;
    //   // window.location.href = `http://localhost:3000/login`;
    // });
  }, []);
  return (
    <div className="container">
      {loading === false && (
        <UserContext.Provider value={{ user, setUser }}>
          <Router>
            {user !== null && <Header />}
            <Switch>
              <LoggedRoute path="/login" component={Login} />
              <PrivateRoute exact path="/" component={Main} />
              <PrivateRoute path="/add" component={Add} />
              <PrivateRoute path="/category" component={Category} />
              <PrivateRoute path="/edit/:slug">
                <Add edit={"edit"} />
              </PrivateRoute>
              <PrivateRoute
                path="/license/:slug/:license"
                component={License}
              />
              <PrivateRoute path="/list" component={List} />
              <PrivateRoute path="/settings" component={Settings} />
              <PrivateRoute path="/report" component={Report} />
              <PrivateRoute path="/logs" component={Logs} />
              <PrivateRoute path="/view/:slug" component={View} />
              <PrivateRoute path="/approve" component={Approve} />
              <PrivateRoute
                path="/registration/:slug/:edit"
                component={Registration}
              />
              <PrivateRoute
                path="/registration/:slug"
                component={Registration}
              />
              <Route component={NotFound} />
            </Switch>
          </Router>
          {/* <Footer /> */}
        </UserContext.Provider>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
