import { Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { useContext } from "react";
import { UserContext } from "./Hooks/UserContext";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(UserContext);
  const token = localStorage.getItem("token");
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          user === null ? (
            <>
              <Redirect to="/login" />
              {toast.error("Please Login In")}
            </>
          ) : (
            <Component {...props} />
          )
        }
      />
    </>
  );
};

export default PrivateRoute;
