import TextField from "@material-ui/core/TextField";
function stepOne({ change, info }) {
  return (
    <div className="add-card">
      <center>Foriegn Investment</center>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Country of incorparation"
            className="input-50"
            size="small"
            value={info[0].country}
            onChange={(e) => change(0, e.target.value, "country")}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Company Address"
            className="input-50"
            size="small"
            value={info[0].address}
            onChange={(e) => change(0, e.target.value, "address")}
          />
        </div>
      </div>

      <div className="flex text-area">
        <div className="text-50">
          <TextField
            id="date"
            label="Date of incorparation"
            type="date"
            className="input-50"
            defaultValue="2017-05-24"
            InputLabelProps={{
              shrink: true,
            }}
            value={info[0].date}
            onChange={(e) => change(0, e.target.value, "date")}
          />
        </div>
        <div className="text-50">
          <TextField
            id="standard-basic"
            label="Number of incorparation"
            className="input-50"
            size="small"
            value={info[0].number}
            onChange={(e) => change(0, e.target.value, "number")}
          />
        </div>
      </div>
    </div>
  );
}

export default stepOne;
