import React from "react";
import Button from "@material-ui/core/Button";

import {
  BrowserRouter as Router,
  useParams,
  useHistory,
} from "react-router-dom";
import { findCompany } from "../../Utils/API_fetching";
import { structure } from "../../Utils/register_structure";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import BreadCrumbs from "../BreadCrumbs";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import StepFour from "./stepFour";
import StepFive from "./stepFive";
import StepSix from "./stepSix";
import StepSeven from "./stepSeven";
import StepEight from "./stepEight";
import StepNine from "./stepNine";
import StepTen from "./stepTen";
import Loading from "../Loading";

import { registrationValidation } from "../../Utils/validation";
import { registerCompany, editRegisterCompany } from "../../Utils/API_fetching";

function Registration() {
  let { slug, edit } = useParams();
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [info, setInfo] = useState(structure);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    findCompany(slug)
      .then((res) => {
        if (edit === undefined) {
          setLoading(false);
        } else {
          const {
            sectionTwo,
            sectionThree,
            sectionFour,
            sectionFive,
            sectionSix,
            sectionSeven,
            sectionEight,
            sectionNine,
            sectionTen,
            sectionEleven,
          } = res.data.company.details;
          sectionTwo.date = sectionTwo.date.split("T")[0];
          sectionFour.dateOfBirth = sectionFour.dateOfBirth.split("T")[0];
          sectionFive.map((shareholder, index) => {
            sectionFive[index].dateOfBirth =
              shareholder.dateOfBirth.split("T")[0];
          });
          sectionTen.dateOfBirth = sectionTen.dateOfBirth.split("T")[0];
          setInfo([
            { ...sectionTwo },
            { ...sectionThree },
            { ...sectionFour },
            [...sectionFive],
            { ...sectionSix },
            { ...sectionSeven },
            { ...sectionEight },
            { ...sectionNine },
            { ...sectionTen },
            { ...sectionEleven },
          ]);
          setLoading(false);
          // console.log([
          //   { ...sectionTwo },
          //   { ...sectionThree },
          //   { ...sectionFour },
          //   [...sectionFive],
          //   { ...sectionSix },
          //   { ...sectionSeven },
          //   { ...sectionEight },
          //   { ...sectionNine },
          //   { ...sectionTen },
          //   { ...sectionEleven },
          // ]);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
        history.push("/list");
      });
  }, []);

  function change(currentStep, value, key, extra) {
    if (currentStep === 7 && key !== "numberOfEmployees") {
      var newInfo = [...info];
      newInfo[currentStep][key][extra] = value;
      setInfo(newInfo);
      return;
    }
    var newInfo = [...info];
    newInfo[currentStep][`${key}`] = value;
    setInfo(newInfo);
  }

  function nextStep() {
    //Validation
    let error = registrationValidation(info, step);
    if (error === false) {
      setStep((step) => step + 1);
    }
  }

  async function save() {
    try {
      if (submitted === true) return;
      let formData = new FormData();
      formData.append("data", JSON.stringify(info));
      formData.append("projectProposal", info[9].projectProposal);
      formData.append("companyLicense", info[9].companyLicense);
      formData.append("companyAgreement", info[9].companyAgreement);

      let error = registrationValidation(info, step);
      if (error === false) {
        await registerCompany(formData, slug);

        toast.success("Company information saved");
        window.setTimeout(() => {
          history.push(`/view/${slug}`);
        }, 1500);
      }
      setSubmitted(false);
    } catch (e) {
      setSubmitted(false);
      toast.error(e.message);
    }
  }

  async function update() {
    try {
      let formData = new FormData();
      formData.append("data", JSON.stringify(info));
      formData.append("projectProposal", info[9].projectProposal);
      formData.append("companyLicense", info[9].companyLicense);
      formData.append("companyAgreement", info[9].companyAgreement);
      let error = registrationValidation(info, step);

      if (error === false) {
        await editRegisterCompany(formData, slug);
        toast.success("Company information updated");
        window.setTimeout(() => {
          history.push(`/view/${slug}`);
        }, 1500);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function addShareholder() {
    var newInfo = [...info];
    newInfo[3] = [
      ...newInfo[3],
      {
        fullName: "",
        position: "",
        shareholder: "",
        legalDocument: "",
        number: "",
        dateOfBirth: "",
        citizenship: "",
        address: "",
        tell: "",
      },
    ];
    setInfo(newInfo);
  }

  function removeShareholder(i) {
    const newArray = [...info];
    console.log(newArray[3]);
    newArray[3].splice(i, 1);
    setInfo(newArray);
  }

  function shareholderChange(index, value, key) {
    const newArray = [...info];
    newArray[3][index][`${key}`] = value;
    setInfo(newArray);
  }

  return <div>{loading === true ? <Loading /> : form()}</div>;

  function form() {
    return (
      <>
        <BreadCrumbs current={"Registration Process"} />
        <div className="flex form-position">
          <div className={step >= 0 && "active"}>1</div>
          <div className={step >= 1 && "active"}>2</div>
          <div className={step >= 2 && "active"}>3</div>
          <div className={step >= 3 && "active"}>4</div>
          <div className={step >= 4 && "active"}>5</div>
          <div className={step >= 5 && "active"}>6</div>
          <div className={step >= 6 && "active"}>7</div>
          <div className={step >= 7 && "active"}>8</div>
          <div className={step >= 8 && "active"}>9</div>
          <div className={step >= 9 && "active"}>10</div>
        </div>

        {step === 0 && <StepOne change={change} info={info} />}
        {step === 1 && <StepTwo change={change} info={info} />}
        {step === 2 && <StepThree change={change} info={info} />}
        {step === 3 && (
          <StepFour
            info={info}
            removeShareholder={removeShareholder}
            shareholderChange={shareholderChange}
            addShareholder={addShareholder}
          />
        )}

        {step === 4 && <StepFive info={info} change={change} />}
        {step === 5 && <StepSix change={change} info={info} />}
        {step === 6 && <StepSeven change={change} info={info} />}
        {step === 7 && <StepEight change={change} info={info} />}
        {step === 8 && <StepNine change={change} info={info} />}
        {step === 9 && <StepTen change={change} info={info} />}

        <div className="submission flex">
          <div>
            {step !== 0 && (
              <Button
                variant="contained"
                className="btn-info"
                onClick={() => setStep((step) => step - 1)}
              >
                Prev
              </Button>
            )}
          </div>
          {step < 9 && (
            <Button variant="contained" className="btn-info" onClick={nextStep}>
              Next
            </Button>
          )}
          {step === 9 && edit === undefined && (
            <Button
              variant="contained"
              className="btn-submit"
              onClick={() => {
                save();
                setSubmitted(true);
              }}
            >
              Submit
            </Button>
          )}

          {step === 9 && edit !== undefined && (
            <Button variant="contained" className="btn-submit" onClick={update}>
              Update
            </Button>
          )}
        </div>
      </>
    );
  }
}

export default Registration;
