import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { MdClose } from "react-icons/md";
function stepFour({
  info,
  removeShareholder,
  addShareholder,
  shareholderChange,
}) {
  return (
    <div className="add-card">
      <center>Company Owners & Main shareholders</center>
      {info[3].map((x, i) => (
        <>
          {info[3].length > 1 && i !== 0 && (
            <div
              style={{
                margin: "10px 0px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                top: "20px",
              }}
            >
              <Button
                variant="contained"
                className="btn-remove"
                onClick={() => removeShareholder(i)}
              >
                <MdClose size={20} />
              </Button>
            </div>
          )}
          <div className="flex text-area">
            <div className="text-100">
              <TextField
                id="standard-basic"
                label="Full Name"
                className="input-50"
                size="small"
                value={info[3][i].fullName}
                onChange={(e) =>
                  shareholderChange(i, e.target.value, "fullName")
                }
              />
            </div>
          </div>
          <div className="flex text-area">
            <div className="text-50">
              <TextField
                id="standard-basic"
                label="Position"
                className="input-50"
                size="small"
                value={info[3][i].position}
                onChange={(e) =>
                  shareholderChange(i, e.target.value, "position")
                }
              />
            </div>
            <div className="text-50">
              <TextField
                id="standard-basic"
                label="Shareholder %"
                className="input-50"
                size="small"
                value={info[3][i].shareholder}
                onChange={(e) =>
                  shareholderChange(i, e.target.value, "shareholder")
                }
              />
            </div>
          </div>

          <div className="flex text-area">
            <div className="text-50">
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">
                  Legal Document
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={info[3][i].legalDocument}
                  onChange={(e) =>
                    shareholderChange(i, e.target.value, "legalDocument")
                  }
                  className="input-100"
                  style={{ width: "100%" }}
                >
                  <MenuItem value={"Passport"}>Passport</MenuItem>
                  <MenuItem value={"National ID"}>National ID</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="text-50">
              <TextField
                id="standard-basic"
                label="Number"
                className="input-50"
                size="small"
                value={info[3][i].number}
                onChange={(e) => shareholderChange(i, e.target.value, "number")}
              />
            </div>
          </div>

          <div className="flex text-area">
            <div className="text-50">
              <TextField
                id="date"
                label="Date of birth"
                type="date"
                className="input-50"
                value={info[3][i].dateOfBirth}
                onChange={(e) =>
                  shareholderChange(i, e.target.value, "dateOfBirth")
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="text-50">
              <TextField
                id="standard-basic"
                label="Citizenship"
                className="input-50"
                size="small"
                value={info[3][i].citizenship}
                onChange={(e) =>
                  shareholderChange(i, e.target.value, "citizenship")
                }
              />
            </div>
          </div>
          <div className="flex text-area">
            <div className="text-50">
              <TextField
                id="standard-basic"
                label="Address"
                className="input-50"
                size="small"
                value={info[3][i].address}
                onChange={(e) =>
                  shareholderChange(i, e.target.value, "address")
                }
              />
            </div>
            <div className="text-50">
              <TextField
                id="standard-basic"
                label="Tell"
                className="input-50"
                size="small"
                value={info[3][i].tell}
                onChange={(e) => shareholderChange(i, e.target.value, "tell")}
              />
            </div>
          </div>
        </>
      ))}
      <div className="btn-add-form">
        <Button
          variant="contained"
          className="btn-info"
          onClick={addShareholder}
        >
          Add new shareholder
        </Button>
      </div>
    </div>
  );
}

export default stepFour;
