import BreadCrumbs from "./BreadCrumbs";
import Search from "./Search";
import Pagination from "./Pagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { MdDone, MdVisibility } from "react-icons/md";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  getApprovalCompanies,
  approveCompany,
  deleteACompany,
} from "./../Utils/API_fetching";
import { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function Approve() {
  const [search, setSearch] = useState("");
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [paging, setPaging] = useState({
    limit: 8,
    page: 1,
    pages: 1,
    skip: 0,
  });
  let history = useHistory();

  function find(value) {
    setSearch(value);
    setCurrentPage(1);
  }

  function changePage(page) {
    setCurrentPage(page);
  }

  async function approve(slug) {
    try {
      await approveCompany(slug);
      toast.success("✅ Company has been approved");
      window.setTimeout(() => {
        history.push(`/view/${slug}`);
      }, 1500);
    } catch (e) {
      toast.error(`⚠ ${e.response.data.message}`);
    }
  }
  async function reject(id) {
    deleteACompany(id)
      .then((res) => {
        toast.success("Company rejected successfully");
        fetchData();
      })
      .catch((e) => {
        toast.error(e.response.data.message);
        history.push("/");
      });
  }
  async function fetchData() {
    const data = await getApprovalCompanies(search, currentPage);
    setCompanies(data.data.companies);
    setPaging(data.data.paging);
    setLoading(false);
  }

  useEffect(() => {
    fetchData().catch((e) => {
      toast.error(e.response.data.message);
      history.push("/");
    });
  }, [search, currentPage]);

  return (
    <div>
      <BreadCrumbs current={"Company List"} />
      <Search find={find} />

      <TableContainer component={Paper} className="list-table">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell align="right">Investment Origin</TableCell>
              <TableCell align="right">Investment Sector</TableCell>
              <TableCell align="right">Approve</TableCell>
              <TableCell align="right">View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell
                  colspan="5"
                  component="th"
                  scope="row"
                  style={{ padding: "30px", textAlign: "center" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {companies.length === 0 && loading === false && (
              <TableRow>
                <TableCell
                  colspan="5"
                  component="th"
                  scope="row"
                  style={{
                    padding: "30px",
                    textAlign: "center",
                  }}
                >
                  <h4>⛔ Company you searched for doesn't exist</h4>
                </TableCell>
              </TableRow>
            )}
            {companies.map((company) => (
              <TableRow key={company._id}>
                <TableCell component="th" scope="row">
                  {company.companyName}{" "}
                </TableCell>

                <TableCell align="right">{company.investmentOrigin}</TableCell>
                <TableCell align="right">{company.investmentSector}</TableCell>
                <TableCell align="right">
                  {company.approved === false ? (
                    <>
                      <button
                        className="btn-complete"
                        onClick={() => approve(company.slug)}
                      >
                        Approve
                      </button>
                      <button
                        className="btn-danger"
                        style={{ marginLeft: "2px" }}
                        onClick={() => reject(company._id)}
                      >
                        Reject
                      </button>
                    </>
                  ) : (
                    <MdDone color={"green"} size={20} />
                  )}
                </TableCell>
                <TableCell align="right">
                  <Link to={`/view/${company.slug}`} className="link">
                    <MdVisibility size={20} />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {paging.pages > 1 && (
        <Pagination paging={paging} changePage={changePage} />
      )}
    </div>
  );
}

export default Approve;
