import { toast } from "react-toastify";
import {
  companySchema,
  stepOneSchema,
  stepTwoSchema,
  stepThreeSchema,
  stepFourSchema,
  stepFiveSchema,
  stepSixSchema,
  stepSevenSchema,
  stepEightSchema,
  stepNineSchema,
  stepTenSchema,
} from "./joi";

export function companyValidation(data) {
  let error = false;
  const validation = companySchema.validate(data);
  if (validation.error !== undefined) {
    error = true;
    toast.error(validation.error.details[0].message);
  }
  return error;
}

export function registrationValidation(data, step) {
  let error = false;

  const validation = validationSteps(data, step);
  if (step === 7) {
    let local =
      parseInt(data[step].local.managerial) +
      parseInt(data[step].local.skilled) +
      parseInt(data[step].local.unskilled);
    let foreign =
      parseInt(data[step].foreign.managerial) +
      parseInt(data[step].foreign.skilled);
    let total = local + foreign;
    if (parseInt(data[step].numberOfEmployees) !== total) {
      error = true;
      toast.error(
        "Number of employees does not match the total of local & foreign employees"
      );
    }
  }

  if (step === 4) {
    const stepFourTotal =
      parseInt(data[4].debt) +
      parseInt(data[4].startingCapital) +
      parseInt(data[4].equityAmount);

    if (stepFourTotal > data[4].investmentValue) {
      error = true;
      toast.error(
        "Starting Capital, Loans & Equity amount exceeds total investment amount"
      );
    }
  }

  if (step === 5) {
    const stepFiveTotal =
      parseInt(data[5].land) +
      parseInt(data[5].plant) +
      parseInt(data[5].workingCapital);
    if (stepFiveTotal > data[4].investmentValue) {
      error = true;
      toast.error(
        `Land, Plant & Working Capital amount exceeds total investment amount $${data[4].investmentValue}`
      );
    }
  }

  if (step === 6) {
    const stepSixTotal =
      parseInt(data[6].yearOne) +
      parseInt(data[6].yearTwo) +
      parseInt(data[6].yearThree);
    if (stepSixTotal > data[4].investmentValue) {
      error = true;
      toast.error(
        `Year One, Year Two & Year Three amount exceeds total investment amount $${data[4].investmentValue}`
      );
    }
  }

  if (validation.error !== undefined) {
    error = true;
    toast.error(validation.error.details[0].message);
  }
  return error;
}

function validationSteps(data, step) {
  if (step === 0) {
    return stepOneSchema.validate(data[step]);
  } else if (step === 1) {
    return stepTwoSchema.validate(data[step]);
  } else if (step === 2) {
    return stepThreeSchema.validate(data[step]);
  } else if (step === 3) {
    return stepFourSchema.validate(data[step]);
  } else if (step === 4) {
    return stepFiveSchema.validate(data[step]);
  } else if (step === 5) {
    return stepSixSchema.validate(data[step]);
  } else if (step === 6) {
    return stepSevenSchema.validate(data[step]);
  } else if (step === 7) {
    return stepEightSchema.validate(data[step]);
  } else if (step === 8) {
    return stepNineSchema.validate(data[step]);
  } else if (step === 9) {
    return stepTenSchema.validate(data[step]);
  }
}

// export const validation = (){
//     let error = false;
//     const required = [];
//     if (step === 1) {
//       const newArray = [...info];
//       if (
//         info[1].typeOfBusiness === "Foreign Solo Proprietorship" ||
//         info[1].typeOfBusiness === "Foreign Partnership"
//       ) {
//         newArray[4] = {
//           investmentValue: newArray[4].investmentValue,
//           startingCapital: newArray[4].startingCapital,
//           foreign: {
//             debt: "",
//             equityAmount: "",
//             equity: "",
//             preferenceShares: "",
//           },
//         };
//         setInfo(newArray);
//         setToggle("foreign");
//       } else {
//         newArray[4] = {
//           investmentValue: newArray[4].investmentValue,
//           startingCapital: newArray[4].startingCapital,
//           local: {
//             debt: "",
//             equityAmount: "",
//             equity: "",
//             preferenceShares: "",
//           },
//         };
//         setInfo(newArray);
//         setToggle("local");
//       }
//     }

//     if (step === 3) {
//       info[step].map((el, i) => {
//         Object.keys(info[step][i]).map((key, index) => {
//           if (required.includes(key) && info[step][i][key] === "") {
//             error = true;
//             return toast.error(`⚠ Please fill out ${key} `);
//           }
//         });
//       });
//     }

//     if (step === 4) {
//       if (info[4].investmentValue < 100000) {
//         error = true;
//         return toast.error(`⚠ Investment value must be more then 100,000 USD`);
//       }
//     }

//     Object.keys(info[step]).map((key, index) => {
//       if (required.includes(key) && info[step][key] === "") {
//         error = true;
//         return toast.error(`⚠ Please fill out ${key} `);
//       }
//     });

//     //Validation
//     if (error === false) {
//       setStep((step) => step + 1);
//     }
// }
