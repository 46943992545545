import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { DropzoneArea } from "material-ui-dropzone";

function stepTen({ change, info }) {
  return (
    <div className="add-card">
      <center>Upload required documents</center>
      <div className="text-area">
        <div className="text-100">
          <div>
            <small>Detailed project propsal</small>
          </div>

          <input
            type="file"
            name="projectProposal"
            accept="application/pdf"
            onChange={(e) => change(9, e.target.files[0], "projectProposal")}
          />
          <div>
            <small>Company license</small>
          </div>
          <input
            type="file"
            name="companyLicense"
            accept="application/pdf"
            // value={info[9].companyLicense}
            onChange={(e) => change(9, e.target.files[0], "companyLicense")}
          />
          <div>
            <small>Company law / shareholders agreement</small>
          </div>
          <input
            type="file"
            name="companyAgreement"
            accept="application/pdf"
            // value={info[9].companyAgreement}
            onChange={(e) => change(9, e.target.files[0], "companyAgreement")}
          />
        </div>
      </div>
    </div>
  );
}

export default stepTen;
