import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import { categories, addCategory, deleteCategory } from "../Utils/API_fetching";
import BreadCrumbs from "./BreadCrumbs";
import { toast } from "react-toastify";
import { MdClose } from "react-icons/md";
import CategoryDropdown from "../Utils/CategoryDropdown";
import ReactPaginate from "react-paginate";
import Loading from "./Loading";
function Category() {
  const [cat, setCat] = useState([]);
  const [modal, setModal] = useState({ state: false, id: null });
  const [input, setInput] = useState("");
  const [data, setData] = useState({ investmentSector: "" });
  const [paging, setPaging] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    categories(currentPage)
      .then((res) => {
        setCat(res.data.categories);
        setPaging(res.data.paging);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [cat]);

  function addCat() {
    addCategory({ category: input })
      .then((res) => {
        setInput("");
        toast.success("Create the new category");
      })
      .catch((e) => console.log(e.response.data.message));
  }

  function deleteCat(id) {
    let sector = data.investmentSector;
    if (sector === "") {
      return toast.error("Select a sector");
    }
    deleteCategory(id, sector)
      .then((res) => {
        setInput("");
        toast.success("Deleted category");
        setModal({ state: false, id: null });
      })
      .catch((e) => toast.error(e.response.data.message));
  }

  const handlePageClick = (data) => {
    let selected = data.selected;
    console.log(selected);
    setCurrentPage(selected + 1);
  };
  return loading ? <Loading /> : table();
  function table() {
    return (
      <div>
        {modal.state && modalDiv()}
        <BreadCrumbs current={"Sector Categories"} />
        <div className="flex" style={{ width: "50%", margin: "auto" }}>
          <TextField
            id="text"
            label="Add investment sector"
            type="text"
            className="input-50"
            InputLabelProps={{
              shrink: true,
            }}
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <Button
            variant="outlined"
            style={{ marginLeft: "20px" }}
            onClick={addCat}
          >
            Add
          </Button>
        </div>
        <TableContainer component={Paper} className="list-table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Investment Sector</b>
                </TableCell>
                <TableCell align="right">
                  <b>Delete</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cat.map((value) => (
                <TableRow key={value._id}>
                  <TableCell>{value.category}</TableCell>

                  <TableCell align="right">
                    <MdClose
                      color={"red"}
                      size={20}
                      className="hover"
                      onClick={() => setModal({ state: true, id: value._id })}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {paging.pages > 1 && (
          <ReactPaginate
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={paging.pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        )}
      </div>
    );
  }
  function modalDiv() {
    return (
      <div
        className="info-modal"
        // onClick={() => {
        //   setModal(!modal);
        //   setData({ investmentSector: "" });
        // }}
      >
        <div className="info-modal-card">
          <h4>Category</h4>
          <br />
          <p>Are you sure you want to delete this category?</p>
          <br />
          <small>
            <b>Note:</b> For the companies with this category choose a category
            to replace
          </small>
          <CategoryDropdown
            value={data.investmentSector}
            set={setData}
            data={data}
          />
          <b />
          <div style={{ paddingTop: 20 }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 10 }}
              onClick={() => deleteCat(modal.id)}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setModal(!modal);
                setData({ investmentSector: "" });
              }}
            >
              No
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Category;
