import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import { changeUserPass } from "../../Utils/API_fetching";
import { toast } from "react-toastify";
function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  function changePass() {
    changeUserPass({ oldPassword, newPassword, confirmPassword })
      .then((res) => toast.success(res.data.message))
      .catch((e) => toast.error(e.response.data.message));
  }
  return (
    <div style={{ width: "500px" }}>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            type="password"
            label="Old Password"
            className="input-100"
            size="small"
            style={{ width: "100%" }}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="New Password"
            type="password"
            className="input-100"
            size="small"
            style={{ width: "100%" }}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Confirm New Password"
            type="password"
            className="input-100"
            size="small"
            style={{ width: "100%" }}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-10">
        <Button
          variant="contained"
          className="btn-submit"
          onClick={() => changePass()}
        >
          Change Password
        </Button>
      </div>
    </div>
  );
}

export default ChangePassword;
