import BreadCrumbs from "./BreadCrumbs";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import Loading from "./Loading";
import { useEffect, useState } from "react";
import { getReports } from "../Utils/API_fetching";
import CategoryDropdown from "../Utils/CategoryDropdown";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { GrFormView } from "react-icons/gr";
import { MdOutlineClose } from "react-icons/md";
import * as XLSX from "xlsx";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaSquareMinus } from "react-icons/fa6";
function Report() {
  const [report, setReport] = useState([]);
  const [license, setLicense] = useState();
  const [existingCompanies, setExistingCompanies] = useState();
  const [newCompanies, setNewCompanies] = useState();
  const [expiredLicenses, setExpiredLicenses] = useState();
  const [expiredCompanies, setExpiredCompanies] = useState([]);
  const [modal, setModal] = useState(false);
  const [tableModal, setTableModal] = useState({
    open: false,
    data: [],
    title: "",
  });
  const [loading, setLoading] = useState(true);

  const [filter, setFilter] = useState({
    from: undefined,
    to: undefined,
    category: undefined,
  });

  const [category, setCategory] = useState("");
  let history = useHistory();
  useEffect(() => {
    getReports(filter)
      .then((res) => {
        const expiredList =
          res.data.expiredLicenseReport.length > 0
            ? res.data.expiredLicenseReport[0].companies
            : [];
        setExpiredCompanies(expiredList);
        setReport(res.data.report);
        setLicense(res.data.licenseReport);
        setExistingCompanies(res.data.existingCompanies);
        setNewCompanies(res.data.newCompanies);
        setExpiredLicenses(res.data.expiredLicenseReport);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        // toast.error(e.response.data.message || "Error");
        history.push("/");
      });
  }, [filter]);
  return (
    <>
      <BreadCrumbs current={"Report"} />
      {loading ? <Loading /> : render()}
      {tableModal.open && modalDiv()}
    </>
  );

  function render() {
    return (
      <>
        <div className="search center flex">
          <TextField
            id="date"
            label="From"
            type="date"
            className="input-50"
            style={{ width: "25%", margin: "0px 25px" }}
            value={filter.from}
            onChange={(e) => setFilter({ ...filter, from: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="date"
            label="To"
            type="date"
            className="input-50"
            style={{ width: "25%", margin: "0px 20px" }}
            value={filter.to}
            onChange={(e) => setFilter({ ...filter, to: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className="text-50">
            <CategoryDropdown value={category} set={setFilter} data={filter} />
          </div>
        </div>
        <div className="main-nav flex">
          {/* CARD */}
          <div
            className="col-4 nav-hover"
            style={{ width: "210px " }}
            onClick={() =>
              setTableModal({
                open: !tableModal.open,
                data: newCompanies[0].companies,
                title: "New Companies",
              })
            }
          >
            <div className="report-card">
              <h3 className="nav-text">New companies registered</h3>
              <div style={{ padding: "10px 0px" }}></div>
              <h4>
                {newCompanies.length === 0 ? 0 : newCompanies[0].registered}
              </h4>
            </div>
          </div>
          {/* CARD */}
          {/* CARD */}
          <div
            className="col-4 nav-hover"
            style={{ width: "210px " }}
            onClick={() =>
              setTableModal({
                open: !tableModal.open,
                data: existingCompanies[0].companies,
                title: "Renewed Companies",
              })
            }
          >
            <div className="report-card">
              <h3 className="nav-text">Renewed companies registered</h3>
              <div style={{ padding: "10px 0px" }}></div>
              <h4>
                {existingCompanies.length === 0
                  ? 0
                  : existingCompanies[0].registered}
              </h4>
            </div>
          </div>
          {/* CARD */}
          {/* CARD */}
          <div
            className="col-4 nav-hover"
            style={{ width: "210px " }}
            onClick={() =>
              setTableModal({
                open: !tableModal.open,
                data: license[0].companies,
                title: "Licenses Companies",
              })
            }
          >
            <div className="report-card">
              <h3 className="nav-text">Licenses Created</h3>
              <div style={{ padding: "10px 0px" }}></div>
              {license.length !== 0 ? (
                <h4>{license[0].licenses}</h4>
              ) : (
                <h4>0</h4>
              )}
            </div>
          </div>
          {/* CARD */}
          {/* CARD */}
          <div
            className="col-4 nav-hover"
            style={{ width: "210px " }}
            // onClick={() => setModal(!modal)}
            onClick={() =>
              setTableModal({
                open: !tableModal.open,
                data: expiredCompanies,
                title: "Expired licenses",
              })
            }
          >
            <div className="report-card">
              <h3 className="nav-text">Expired Licenses</h3>
              <div style={{ padding: "10px 0px" }}></div>
              {expiredLicenses.length !== 0 ? (
                <h4>{expiredLicenses[0].licenses}</h4>
              ) : (
                <h4>0</h4>
              )}
            </div>
          </div>
          {/* CARD */}
          {/* CARD */}
          <div className="col-4 nav-hover" style={{ width: "210px " }}>
            <div className="report-card">
              <h3 className="nav-text">Investment Made</h3>
              <div style={{ padding: "10px 0px" }}></div>
              <h4>
                $
                {report.length === 0
                  ? 0
                  : report[0].totalInvestment.toLocaleString()}
              </h4>
            </div>
          </div>
          {/* CARD */}
          {/* CARD */}
          <div className="col-4 nav-hover" style={{ width: "210px " }}>
            <div className="report-card">
              <h3 className="nav-text">No of people employed</h3>
              <div style={{ padding: "10px 0px" }}></div>
              <h4>{report.length === 0 ? 0 : report[0].employed}</h4>
            </div>
          </div>
          {/* CARD */}
          {/* CARD */}
          <div className="col-4 nav-hover" style={{ width: "210px " }}>
            <div className="report-card">
              <h3 className="nav-text">Locals employed</h3>
              <div style={{ padding: "10px 0px" }}></div>
              <h4>{report.length === 0 ? 0 : report[0].localEmployees}</h4>
            </div>
          </div>
          {/* CARD */}
          {/* CARD */}
          <div className="col-4 nav-hover" style={{ width: "210px " }}>
            <div className="report-card">
              <h3 className="nav-text">Foreigners employed</h3>
              <div style={{ padding: "10px 0px" }}></div>
              <h4>{report.length === 0 ? 0 : report[0].foreignEmployees}</h4>
            </div>
          </div>
          {/* CARD */}
          {/* CARD */}
          <div className="col-4 nav-hover" style={{ width: "210px " }}>
            <div className="report-card">
              <h3 className="nav-text">Year 1 Investments</h3>
              <div style={{ padding: "10px 0px" }}></div>
              <h4>
                ${report.length === 0 ? 0 : report[0].yearOne.toLocaleString()}
              </h4>
            </div>
          </div>
          {/* CARD */}
          {/* CARD */}
          <div className="col-4 nav-hover" style={{ width: "210px " }}>
            <div className="report-card">
              <h3 className="nav-text">Year 2 Investments</h3>
              <div style={{ padding: "10px 0px" }}></div>
              <h4>
                ${report.length === 0 ? 0 : report[0].yearTwo.toLocaleString()}
              </h4>
            </div>
          </div>
          {/* CARD */}
          {/* CARD */}
          <div className="col-4 nav-hover" style={{ width: "210px " }}>
            <div className="report-card">
              <h3 className="nav-text">Year 3 Investments</h3>
              <div style={{ padding: "10px 0px" }}></div>
              <h4>
                $
                {report.length === 0 ? 0 : report[0].yearThree.toLocaleString()}
              </h4>
            </div>
          </div>
          {/* CARD */}
        </div>
      </>
    );
  }

  function modalDiv() {
    if (!tableModal.data || tableModal.data.length === 0) return;

    const handleDownload = () => {
      // Convert the data array to a worksheet
      const dataWithoutSlug = tableModal.data.map(({ slug, ...rest }) => rest);

      const worksheet = XLSX.utils.json_to_sheet(dataWithoutSlug);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, tableModal.title);

      // Generate Excel file and trigger download
      XLSX.writeFile(workbook, `${tableModal.title}-data.xlsx`);
    };
    return (
      <div className="report-modal">
        <div className="report-modal-card">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <button
                style={{
                  background: "green",
                  border: 0,
                  padding: 10,
                  color: "white",
                  display: "flex",
                  alignContent: "center",
                  cursor: "pointer",
                }}
                onClick={handleDownload}
              >
                <RiFileExcel2Line size={20} style={{ marginRight: 10 }} />
                Download Excel
              </button>
            </div>
            <h4 style={{ fontSize: "20px", fontWeight: "bold" }}>
              {tableModal.title}
            </h4>

            <div
              onClick={() => setTableModal({ ...tableModal, open: false })}
              style={{
                cursor: "pointer",
              }}
            >
              <MdOutlineClose size={18} />
            </div>
          </div>
          <TableContainer
            component={Paper}
            style={{ marginTop: "30px", maxHeight: "500px", overflow: "auto" }}
          >
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>License No</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell align="right">Sector</TableCell>
                  <TableCell align="right">Ownership</TableCell>
                  <TableCell align="right">Investment Origin</TableCell>
                  <TableCell align="right">Investment Value</TableCell>
                  {tableModal.title === "Expired licenses" && (
                    <>
                      <TableCell align="right">Issued At</TableCell>
                      <TableCell align="right">Expired At</TableCell>
                    </>
                  )}
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Phone</TableCell>
                  <TableCell align="right">View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableModal.data.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      {row.licenseNo || <FaSquareMinus color="#d80000" />}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.companyName}
                    </TableCell>
                    <TableCell align="right">{row.investmentSector}</TableCell>
                    <TableCell align="right">
                      {row.typeOfBusiness || <FaSquareMinus color="#d80000" />}
                    </TableCell>
                    <TableCell align="right">
                      {row.investmentOrigin || (
                        <FaSquareMinus color="#d80000" />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {row.investmentValue || <FaSquareMinus color="#d80000" />}
                    </TableCell>

                    {tableModal.title === "Expired licenses" && (
                      <>
                        <TableCell align="right">
                          {row.issuedDate.split("T")[0]}
                        </TableCell>
                        <TableCell align="right">
                          {row.expiredAt.split("T")[0]}
                        </TableCell>
                      </>
                    )}
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.phone}</TableCell>
                    <TableCell align="right">
                      <Link to={`/view/${row.slug}`}>
                        <GrFormView
                          size={24}
                          style={{ cursor: "pointer", color: "blue" }}
                        />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
}

export default Report;
