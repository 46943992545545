import TextField from "@material-ui/core/TextField";
function stepSix({ info, change }) {
  return (
    <div className="add-card">
      <center>Fixed Cost estimate</center>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Land($)"
            className="input-50"
            size="small"
            value={info[5].land}
            onChange={(e) => change(5, e.target.value, "land")}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Plant & Machinery($)"
            className="input-50"
            size="small"
            value={info[5].plant}
            onChange={(e) => change(5, e.target.value, "plant")}
          />
        </div>
      </div>

      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Working Capital($)"
            className="input-50"
            size="small"
            value={info[5].workingCapital}
            onChange={(e) => change(5, e.target.value, "workingCapital")}
          />
        </div>
      </div>
    </div>
  );
}

export default stepSix;
