import { useState, useEffect } from "react";
import { getUsers, activateUsers } from "../../Utils/API_fetching";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Loading from "../Loading";

function UserList() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getUsers().then((res) => {
      setUsers(res.data.users);
      setLoading(false);
    });
  }, [users]);

  function activation(active, id) {
    //code
    activateUsers(active, id).then((res) => {
      console.log(res);
    });
  }
  return (
    <>
      {loading ? (
        <div style={{ margin: "auto", width: "40px" }}>
          <CircularProgress />
        </div>
      ) : (
        table()
      )}
    </>
  );

  function table() {
    return (
      <TableContainer component={Paper} className="list-table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Department</TableCell>
              <TableCell align="right">Role</TableCell>
              <TableCell align="right">Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell component="th" scope="row">
                  {user.fullName}
                </TableCell>
                <TableCell align="right">{user.email}</TableCell>
                <TableCell align="right">{user.department}</TableCell>
                <TableCell align="right">{user.role}</TableCell>
                <TableCell align="right">
                  {user.active === true ? (
                    <button
                      style={{
                        background: "red",
                        border: "none",
                        color: "white",
                        padding: "3px",
                      }}
                      onClick={() => activation(false, user._id)}
                    >
                      Suspend
                    </button>
                  ) : (
                    <button
                      style={{
                        background: "green",
                        border: "none",
                        color: "white",
                        padding: "3px",
                      }}
                      onClick={() => activation(true, user._id)}
                    >
                      Activate
                    </button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default UserList;
