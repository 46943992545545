import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../Hooks/UserContext";
function Header() {
  const { user, setUser } = useContext(UserContext);
  function ac(name) {
    var str = name;
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    var acronym = matches.join(""); // JSON
    return acronym;
  }

  return (
    <div className="navigation">
      <Link to="/" className="link">
        <img className="logo" src="/somaliland.png" alt="Somaliland" />
      </Link>
      <h4>Moiid License System</h4>
      <div className="profile">
        <Link to="/settings" className="link">
          <Avatar style={{ background: "dodgerblue" }}>
            {ac(user.fullName)}
          </Avatar>
        </Link>
        {/* <img className="profile" src="./profile.png" alt="profile" /> */}
      </div>
    </div>
  );
}

export default Header;
