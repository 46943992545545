import { MdInfoOutline } from "react-icons/md";
import { useState } from "react";

function Footer() {
  function modalDiv() {
    return (
      <div className="info-modal" onClick={() => setModal(!modal)}>
        <div className="info-modal-card">
          <h4>This system is created by</h4>
          <img src="./dev-logo.png" alt="returnd dev" height={"50"} />
          <p>Return dev development studio. </p>
          <br />
          <h4>Contact us:</h4>
          <p>+252 63 362 8935</p>
          <a
            href="https://www.return-dev.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.return-dev.com
          </a>
        </div>
      </div>
    );
  }
  const [modal, setModal] = useState(false);
  return (
    <>
      <p className="footer">
        <div className="footer-circle" onClick={() => setModal(!modal)}>
          <MdInfoOutline size={"28"} color={"green"} />
        </div>
      </p>

      {modal && modalDiv()}
    </>
  );
}

export default Footer;
