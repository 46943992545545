function Pagination({ paging, changePage }) {
  function getPages() {
    return [1, 2, 3, 4, 5];
  }
  return (
    <ul className="pagination">
      {/* {getPages().map(() => (
        <h1>What</h1>
      ))} */}
      {paging.page > 1 && (
        <li onClick={() => changePage(paging.page - 1)}>{paging.page - 1}</li>
      )}
      <li className="active">{paging.page}</li>
      {paging.pages > paging.page && (
        <li onClick={() => changePage(paging.page + 1)}>{paging.page + 1}</li>
      )}
    </ul>
  );
}

export default Pagination;
