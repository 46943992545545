import * as React from "react";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { categories } from "./API_fetching";
import { useEffect, useState } from "react";

export default function CategoryDropdown({ value, set, data }) {
  const [cat, setCat] = useState([]);
  const [selected, setSelected] = useState(value);
  useEffect(() => {
    categories(null)
      .then((res) => {
        setCat(res.data.categories);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Investment Sector</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Age"
          onChange={(e) => {
            setSelected(e.target.value);
            set({ ...data, investmentSector: e.target.value });
          }}
          value={selected}
        >
          {cat.map((item) => (
            <MenuItem value={item.category}>{item.category}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
