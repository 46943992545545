import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
function NotFound() {
  let history = useHistory();

  return (
    <div style={{ height: "70vh", display: "flex" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          margin: "auto",
        }}
      >
        <div>
          <h2 style={{ fontSize: "60px" }}>404</h2>
          <h4 style={{ fontSize: "15px" }}>
            Ooops the page you are looking for does not exist
          </h4>
          <Button
            variant="contained"
            className="btn-info"
            onClick={() => history.push("/")}
            style={{ marginTop: "25px" }}
          >
            Go Home
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
