import { useContext } from "react";
import { UserContext } from "../../Hooks/UserContext";

function UserInfo() {
  const { user } = useContext(UserContext);
  return (
    <div>
      <p>
        <b>Name:</b> {user.fullName}
      </p>
      <p>
        <b>Email:</b> {user.email}
      </p>
      <p>
        <b>Department:</b> {user.department}
      </p>
      <p>
        <b>Role:</b> {user.role}
      </p>
    </div>
  );
}

export default UserInfo;
