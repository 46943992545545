import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useState, useContext } from "react";
import { toast } from "react-toastify";
import { loginUser } from "../Utils/API_fetching";
import { useHistory } from "react-router-dom";
import { UserContext } from "../Hooks/UserContext";
function Login() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, setUser } = useContext(UserContext);

  async function login(e) {
    e.preventDefault();
    try {
      const res = await loginUser(email, password);
      toast.success(`User logged in`);
      window.setTimeout(() => {
        setUser(res.data.data.user);
        window.localStorage.setItem("token", res.data.token);
        history.push("/");
      }, 1500);
    } catch (e) {
      toast.error(` ${e.response.data.message}`);
    }
  }
  return (
    <form className="login-card" onSubmit={(e) => login(e)}>
      <img
        className="logo"
        src="/somaliland.png"
        alt="Somaliland"
        style={{ width: "100px", height: "90px", margin: "10px" }}
      />

      <div>
        <TextField
          id="standard-basic"
          label="Email"
          className="input-100"
          style={{ width: "250px" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          size="small"
        />

        <TextField
          id="standard-basic"
          label="Password"
          type="Password"
          className="input-100"
          style={{ width: "250px" }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          size="small"
        />
      </div>
      <div style={{ paddingTop: "30px" }}>
        <Button type="submit" variant="contained" color="primary">
          Login
        </Button>
      </div>
    </form>
  );
}

export default Login;
