import TextField from "@material-ui/core/TextField";
import { useEffect } from "react";

function StepFive({ info, toggle, change, toggleOnChange }) {
  return (
    <div className="add-card">
      <center>Capital Information</center>
      <div className="flex text-area">
        <div className="text-50">
          <TextField
            id="standard-basic"
            label="Investment Value($)"
            className="input-50"
            size="small"
            value={info[4].investmentValue}
            onChange={(e) => change(4, e.target.value, "investmentValue")}
          />
        </div>
        <div className="text-50">
          <TextField
            id="standard-basic"
            label="Starting Capital($)"
            className="input-50"
            size="small"
            value={info[4].startingCapital}
            onChange={(e) => change(4, e.target.value, "startingCapital")}
          />
        </div>
      </div>

      <div className="flex text-area">
        <div className="text-50">
          <TextField
            id="standard-basic"
            label="Loans (Debts)"
            className="input-50"
            size="small"
            value={info[4].debt}
            onChange={(e) => change(4, e.target.value, "debt")}
          />
        </div>
        <div className="text-50">
          <TextField
            id="standard-basic"
            label="Equity Amount"
            className="input-50"
            size="small"
            value={info[4].equityAmount}
            onChange={(e) => change(4, e.target.value, "equityAmount")}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-50">
          <TextField
            id="standard-basic"
            label="Equity %"
            className="input-50"
            size="small"
            value={info[4].equity}
            onChange={(e) => change(4, e.target.value, "equity")}
          />
        </div>
        <div className="text-50">
          <TextField
            id="standard-basic"
            label="Preference Shares"
            className="input-50"
            size="small"
            value={info[4].preferenceShares}
            onChange={(e) => change(4, e.target.value, "preferenceShares")}
          />
        </div>
      </div>
    </div>
  );
}

export default StepFive;
