import Joi from "joi";

export const loginSchema = Joi.object().keys({
  email: Joi.string().required().label("Email"),
  password: Joi.number().required().min(5).label("Password"),
});

export const companySchema = Joi.object().keys({
  _id: Joi.string().optional(),
  companyName: Joi.string().required().label("Company Name"),
  investmentOrigin: Joi.string().required().label("Investment Origin"),
  investmentSector: Joi.string().required().label("Investment Sector"),
  commencementDate: Joi.string().required().label("Commencement Date"),
  localAddress: Joi.string().required().label("Local Address"),
  headQuaters: Joi.string().required().label("Head Quaters"),
  existing: Joi.boolean().required().label("Existing").messages({
    "any.required": `Existing is a required field`,
    "string.base": `Two`,
    "string.empty": `Three`,
    "string.min": `Four`,
  }),
  phone: Joi.number().required().label("Phone Number"),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
  tell: Joi.number().required().label("Tell"),
  website: Joi.string().required().label("Website"),
  approved: Joi.boolean().label("Approved"),
  slug: Joi.string().optional(),
  createdAt: Joi.string().optional(),
  details: Joi.object().optional(),
  license: Joi.object().optional(),
  __v: Joi.number().optional(),
});

export const stepOneSchema = Joi.object().keys({
  country: Joi.string().required().label("Country Of Incorparation"),
  address: Joi.string().required().label("Company Address"),
  date: Joi.string().required().label("Date Of Incorparation"),
  number: Joi.number().required().label("Number Of Incorparation"),
});

export const stepTwoSchema = Joi.object().keys({
  typeOfBusiness: Joi.string().required().label("Type of Business"),
});

export const stepThreeSchema = Joi.object().keys({
  fullName: Joi.string().required().label("Full Name"),
  nationality: Joi.string().required().label("Nationality"),
  legalDocument: Joi.string().required().label("Legal Document"),
  number: Joi.string().required().label("Number"),
  citizenship: Joi.string().required().label("Number"),
  dateOfBirth: Joi.string().required().label("Date Of Birth"),
  address: Joi.string().required().label("Address"),
  tell: Joi.string().required().label("Tell"),
});

export const stepFour = Joi.object().keys({
  _id: Joi.string().optional(),
  fullName: Joi.string().required().label("Full Name"),
  position: Joi.string().required().label("Position"),
  shareholder: Joi.string().required().label("Shareholder"),
  legalDocument: Joi.string().required().label("Legal Document"),
  number: Joi.string().required().label("Number"),
  dateOfBirth: Joi.string().required().label("Date Of Birth"),
  citizenship: Joi.string().required().label("Citizenship"),
  address: Joi.string().required().label("Address"),
  tell: Joi.number().required().label("Tell"),
});

export const stepFourSchema = Joi.array().items(stepFour);

export const stepFiveSchema = Joi.object().keys({
  investmentValue: Joi.number()
    .min(100000)
    .required()
    .label("Investment Value"),
  startingCapital: Joi.number().required().label("Starting Capital"),
  debt: Joi.number().required().label("Debt"),
  equityAmount: Joi.number().required().label("Equity Amount"),
  equity: Joi.number().required().label("Equity"),
  preferenceShares: Joi.number().required().label("Preference Shares"),
});

export const stepSixSchema = Joi.object().keys({
  land: Joi.number().required().label("Land"),
  plant: Joi.number().required().label("Plant"),
  workingCapital: Joi.number().required().label("Working Capital"),
});

export const stepSevenSchema = Joi.object().keys({
  yearOne: Joi.number().required().label("Year One"),
  yearTwo: Joi.number().required().label("Year Two"),
  yearThree: Joi.number().required().label("Year Three"),
});

export const stepEightSchema = Joi.object().keys({
  numberOfEmployees: Joi.number().required().label("Number Of Employees"),
  local: {
    managerial: Joi.number().required().label("Local Managerial"),
    skilled: Joi.number().required().label("Local Skilled"),
    unskilled: Joi.number().required().label("Local UnSkilled"),
  },
  foreign: {
    managerial: Joi.number().required().label("Foreign Managerial"),
    skilled: Joi.number().required().label("Foreign Skilled"),
  },
});

export const stepNineSchema = Joi.object().keys({
  position: Joi.string().required().label("Position"),
  name: Joi.string().required().label("Name"),
  nationality: Joi.string().required().label("Nationality"),
  legalDocument: Joi.string().required().label("Legal Document"),
  dateOfBirth: Joi.string().required().label("Date Of Birth"),
  address: Joi.string().required().label("Address"),
  phone: Joi.number().required().label("Phone"),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
});
export const stepTenSchema = Joi.object().keys({
  projectProposal: Joi.alternatives(Joi.string(), Joi.object())
    .required()
    .label("Project Proposal"),
  companyLicense: Joi.alternatives(Joi.string(), Joi.object())
    .required()
    .label("Company License"),
  companyAgreement: Joi.alternatives(Joi.string(), Joi.object())
    .required()
    .label("Company Agreement"),
});
