import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    marginTop: "30px",
    minHeight: 360,
    position: "relative",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function Deatils({ company }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="Section One" {...a11yProps(0)} />
          <Tab label="Section Two" {...a11yProps(1)} />
          <Tab label="Section Three" {...a11yProps(2)} />
          <Tab label="Section Four" {...a11yProps(3)} />
          <Tab label="Section Five" {...a11yProps(4)} />
          <Tab label="Section Six" {...a11yProps(5)} />
          <Tab label="Section Seven" {...a11yProps(6)} />
          <Tab label="Section Eight" {...a11yProps(7)} />
          <Tab label="Section Nine" {...a11yProps(8)} />
          <Tab label="Section Ten" {...a11yProps(9)} />
          <Tab label="Section Eleven" {...a11yProps(10)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <p>
            <b>Company Name</b>: {company.companyName}
          </p>
          <p>
            <b>Investment Origin</b>: {company.investmentOrigin}
          </p>
          <p>
            <b>Investment Sector</b>: {company.investmentSector}
          </p>
          <p>
            <b>Commenece Date</b>: {company.date}
          </p>
          <p>
            <b>Local Address</b>: {company.localAddress}
          </p>
          <p>
            <b>Head Quaters</b>: {company.headQuaters}
          </p>
          <p>
            <b>Phone</b>: {company.phone}
          </p>
          <p>
            <b>Email</b>: {company.email}
          </p>
          <p>
            <b>Website</b>: {company.website}
          </p>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {Object.keys(company.details.sectionTwo).map((key, index) => (
            <div>
              <b> {key}</b>: {company.details.sectionTwo[key]}
            </div>
          ))}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {Object.keys(company.details.sectionThree).map((key, index) => (
            <div>
              <b> {key}</b>: {company.details.sectionThree[key]}
            </div>
          ))}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <p>
            <b>Full Name</b>:{company.details.sectionFour.fullName}
          </p>
          <p>
            <b>Nationality</b>:{company.details.sectionFour.nationality}
          </p>
          <p>
            <b>Legal Documents</b>:{company.details.sectionFour.legalDocument}
          </p>
          <p>
            <b>Number</b>:{company.details.sectionFour.number}
          </p>
          <p>
            <b>Citizenship</b>:{company.details.sectionFour.citizenship}
          </p>
          <p>
            <b>Date Of Birth</b>:
            {company.details.sectionFour.dateOfBirth.split("T")[0]}
          </p>
          <p>
            <b>Address</b>:{company.details.sectionFour.address}
          </p>
          <p>
            <b>Tell</b>:{company.details.sectionFour.tell}
          </p>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <p>
            <b>Type of bussiness</b>:
            {company.details.sectionFive.map((item) => (
              <>
                <p>
                  <b>Full Name</b>:{item.fullName}
                </p>
                <p>
                  <b>Position</b>:{item.position}
                </p>
                <p>
                  <b>Shareholder</b>:{item.shareholder}
                </p>
                <p>
                  <b>Legal Document</b>:{item.legalDocument}
                </p>
                <p>
                  <b>Number</b>:{item.number}
                </p>
                <p>
                  <b>Date Of Birth</b>:{item.dateOfBirth.split("T")[0]}
                </p>
                <p>
                  <b>Citizenship</b>:{item.citizenship}
                </p>
                <p>
                  <b>Address</b>:{item.address}
                </p>
                <p>
                  <b>Tell</b>:{item.tell}
                </p>
              </>
            ))}
          </p>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <p>
            <b>Investment Value</b>:{company.details.sectionSix.investmentValue}
          </p>
          <p>
            <b>Starting Capital</b>:{company.details.sectionSix.startingCapital}
          </p>
          <p>
            <b>Debt</b>:{company.details.sectionSix.debt}
          </p>
          <p>
            <b>Equity Amount</b>:{company.details.sectionSix.equityAmount}
          </p>
          <p>
            <b>Equity</b>:{company.details.sectionSix.equity}
          </p>
          <p>
            <b>Perference Shares</b>:
            {company.details.sectionSix.preferenceShares}
          </p>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <p>
            <b>Land</b>:{company.details.sectionSeven.land}
          </p>
          <p>
            <b>Plant</b>:{company.details.sectionSeven.plant}
          </p>
          <p>
            <b>Working Plant</b>:{company.details.sectionSeven.workingCapital}
          </p>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <p>
            <b>Year One</b>:{company.details.sectionEight.yearOne}
          </p>
          <p>
            <b>Year Two</b>:{company.details.sectionEight.yearTwo}
          </p>
          <p>
            <b>Year Three</b>:{company.details.sectionEight.yearThree}
          </p>
        </TabPanel>
        <TabPanel value={value} index={8}>
          <p>
            <b>Total Number of Employees</b>:
            {company.details.sectionNine.numberOfEmployees}
          </p>
          <div style={{ padding: "10px" }}></div>
          <h3>Local</h3>
          <p>
            <b>Managerial</b>:{company.details.sectionNine.local.managerial}
          </p>
          <p>
            <b>Skilled</b>:{company.details.sectionNine.local.skilled}
          </p>
          <p>
            <b>Unskilled</b>:{company.details.sectionNine.local.unskilled}
          </p>
          <div style={{ padding: "10px" }}></div>
          <h3>Foreign</h3>
          <p>
            <b>Managerial</b>:{company.details.sectionNine.foreign.managerial}
          </p>
          <p>
            <b>Skilled</b>:{company.details.sectionNine.foreign.skilled}
          </p>
        </TabPanel>
        <TabPanel value={value} index={9}>
          <p>
            <b>Full Name</b>:{company.details.sectionTen.name}
          </p>
          <p>
            <b>Position</b>:{company.details.sectionTen.position}
          </p>
          <p>
            <b>Nationality</b>:{company.details.sectionTen.nationality}
          </p>
          <p>
            <b>Legal Document</b>:{company.details.sectionTen.legalDocument}
          </p>
          <p>
            <b>Address</b>:{company.details.sectionTen.address}
          </p>
          <p>
            <b>Phone</b>:{company.details.sectionTen.phone}
          </p>
          <p>
            <b>Email</b>:{company.details.sectionTen.email}
          </p>
        </TabPanel>
        <TabPanel value={value} index={10}>
          <p>
            <b>Project Proposal </b>:
            <a
              href={company.details.sectionEleven.projectProposal}
              style={{ textDecoration: "none", color: "dodgerblue" }}
              target="_blank"
              rel="noreferrer"
            >
              View
            </a>
          </p>
          <p>
            <b>Company License</b>:
            <a
              href={company.details.sectionEleven.companyLicense}
              style={{ textDecoration: "none", color: "dodgerblue" }}
              target="_blank"
              rel="noreferrer"
            >
              View
            </a>
          </p>
          <p>
            <b>Company Agreement</b>:
            <a
              href={company.details.sectionEleven.companyAgreement}
              style={{ textDecoration: "none", color: "dodgerblue" }}
              target="_blank"
              rel="noreferrer"
            >
              View
            </a>
          </p>
        </TabPanel>
      </div>
    </>
  );
}
