import axios from "axios";

// const BASE_URL = "https://moip-system.herokuapp.com/api";
// const BASE_URL = "http://localhost:8000/api";

const API = axios.create({
  baseURL: "https://www.moiidlicense.com/api",
  // baseURL: "http://localhost:8000/api",
  withCredentials: true,
  credentials: "include",
});

const authHeader = () => {
  const token = localStorage.getItem("token");

  if (token) {
    return {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
  }
};

export async function getCompanies(search, currentPage) {
  const header = authHeader();
  const res = await API.get(`/company`, {
    params: { search, limit: 8, page: currentPage },
    ...header,
  });
  return res;
}
export async function getApprovalCompanies(search, currentPage) {
  const header = authHeader();
  const res = await API.get(`/company/approval`, {
    params: { search, limit: 8, page: currentPage },
    ...header,
  });
  return res;
}

export async function addCompany(data) {
  const res = await API.post(`/company`, data, authHeader());
  return res;
}

export async function editCompany(slug, data) {
  const res = await API.put(`/company/${slug}`, data, authHeader());
  return res;
}

export async function findCompany(slug) {
  const res = await API.get(`/company/${slug}`, authHeader());
  return res;
}
const config = {
  headers: { "content-type": "multipart/form-data" },
};

const registerAuthHeader = () => {
  const token = localStorage.getItem("token");

  if (token) {
    return {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "multipart/form-data",
      },
    };
  }
  return { headers: { "content-type": "multipart/form-data" } };
};
//TODO: do this
export async function registerCompany(info, slug) {
  const res = await API.post(
    `/company/register/${slug}`,
    info,
    registerAuthHeader()
  );
  return res;
}
export async function editRegisterCompany(info, slug) {
  const res = await API.put(`/company/register/${slug}`, info, authHeader());
  return res;
}

export async function categories(currentPage) {
  let res;
  if (currentPage === null) {
    res = await API.get(`/company/category`, authHeader());
  } else {
    const options = authHeader();
    res = await API.get(`/company/category`, {
      params: { limit: 5, page: currentPage },
      ...options,
    });
  }

  return res;
}
export async function addCategory(body) {
  const res = await API.post(`/company/category`, body, authHeader());
  return res;
}
export async function deleteCategory(id, sector) {
  const options = authHeader();
  const res = await API.delete(`/company/category/${id}`, {
    data: { sector },
    ...options,
  });
  return res;
}
export async function approveCompany(slug) {
  const res = await API.post(`/company/approve/${slug}`, {}, authHeader());
  return res;
}

export async function deleteACompany(id) {
  const res = await API.delete(`/company/${id}`, authHeader());
  return res;
}

export async function createLicense(slug, body) {
  const res = await API.post(`/license/${slug}`, body, authHeader());
  return res;
}

export async function renewLicense(slug, license, body) {
  const res = await API.put(`/license/${slug}/${license}`, body, authHeader());
  return res;
}

export async function viewLicense(slug, license) {
  const res = await API.get(`/license/${slug}/${license}`, authHeader());
  return res;
}

export async function getLogs(currentPage) {
  const options = authHeader();
  const res = await API.get(`/user/logs`, {
    params: { limit: 8, page: currentPage },
    ...options,
  });
  return res;
}

export async function getReports(filter) {
  const options = authHeader();
  const res = await API.get(`/company/report`, {
    params: filter,
    ...options,
  });
  return res;
}

export async function loginUser(email, password) {
  const res = await API.post(`/user/login`, {
    email,
    password,
  });

  return res;
}
export async function createUser(body) {
  const res = await API.post(`/user`, body, authHeader());

  return res;
}
//All users
export async function getUsers() {
  const res = await API.get(`/user`, authHeader());

  return res;
}
//current logged in user
export async function getUser() {
  const res = await API.get(`/user/role`, authHeader());
  return res;
}
export async function activateUsers(active, id) {
  const res = await API.post(`/user/active`, { active, id }, authHeader());

  return res;
}
export async function changeUserPass(body) {
  const res = await API.post("/user/change-password", body, authHeader());
  return res;
}
export async function logoutUser() {
  const res = await API.post("/user/logout");
  return res;
}
