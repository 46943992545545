import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import BreadCrumbs from "../BreadCrumbs";
import Avatar from "@material-ui/core/Avatar";
import NewUser from "./NewUser";
import UserList from "./UserList";
import ChangePassword from "./ChangePassword";
import UserInfo from "./UserInfo";
import {
  BrowserRouter as Router,
  useParams,
  Link,
  useHistory,
} from "react-router-dom";
import { MdModeEdit } from "react-icons/md";
import { useContext } from "react";
import { UserContext } from "../../Hooks/UserContext";
import {
  USER_ROLE,
  APPROVAL_ROLE,
  LICENSER_ROLE,
  SUPER_ADMIN_ROLE,
  ADMIN_ROLE,
} from "../../Utils/UserRoles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function ac(name) {
  var str = name;
  var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
  var acronym = matches.join(""); // JSON
  return acronym;
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    // height: 230,
    // width: 700,
    position: "relative",
    marginTop: "30px",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function Settings() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { user, setUser } = useContext(UserContext);
  return (
    <>
      <BreadCrumbs current={"Profile Settings"} />
      <div className="company-info flex">
        <div className="left-info">
          <div className="main-info">
            <Avatar
              style={{
                background: "dodgerblue",
                margin: "10px auto",
                width: "80px",
                height: "80px",
              }}
            >
              {ac(user.fullName)}
            </Avatar>

            <h3>{user.fullName}</h3>
            <div className="left-text">
              <p className="info">
                <b>Department:</b>
                <br />
                {user.department}
              </p>
              <p className="info">
                <b>Email:</b>
                <br />
                {user.email}
                <br />
              </p>
            </div>
          </div>
        </div>

        {user.role !== ADMIN_ROLE &&
          user.role !== SUPER_ADMIN_ROLE &&
          tabsUser()}
        {user.role !== USER_ROLE &&
          user.role !== APPROVAL_ROLE &&
          user.role !== LICENSER_ROLE &&
          tabsAdmin()}
      </div>
    </>
  );

  function tabsAdmin() {
    return (
      <div className="company-details">
        <div className={classes.root}>
          <Link to={`/edit/`}>
            <button className="btn-edit hover">
              <MdModeEdit />
            </button>
          </Link>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            <Tab label="Account Information" {...a11yProps(0)} />
            <Tab label="New User" {...a11yProps(1)} />
            <Tab label="User List" {...a11yProps(2)} />
            <Tab label="Change Password" {...a11yProps(3)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <UserInfo />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <NewUser />
          </TabPanel>
          <TabPanel value={value} index={2} style={{ width: "76%" }}>
            <UserList />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ChangePassword />
          </TabPanel>
        </div>
      </div>
    );
  }

  function tabsUser() {
    return (
      <div className="company-details">
        <div className={classes.root}>
          <Link to={`/edit/`}>
            <button className="btn-edit hover">
              <MdModeEdit />
            </button>
          </Link>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            <Tab label="Account Information" {...a11yProps(0)} />
            <Tab label="Change Password" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <UserInfo />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChangePassword />
          </TabPanel>
        </div>
      </div>
    );
  }
}
