import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import { toast } from "react-toastify";
import { createUser } from "../../Utils/API_fetching";

function NewUser() {
  const [fullName, setFullName] = useState("");
  const [department, setDepartment] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  async function save() {
    try {
      await createUser({
        fullName,
        department,
        email,
        password,
        role,
      });
      toast.success("User is created");
    } catch (e) {
      toast.error(`⚠ ${e.response.data.message}`);
    }
  }
  return (
    <>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Full Name"
            className="input-50"
            size="small"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Department"
            className="input-50"
            size="small"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Email"
            className="input-100"
            size="small"
            style={{ width: "100%" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Password"
            type="password"
            className="input-50"
            size="small"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="text-50">
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="input-50"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <MenuItem value={"User"}>User</MenuItem>
              <MenuItem value={"Approval"}>Approval</MenuItem>
              <MenuItem value={"Licenser"}>Licenser</MenuItem>
              <MenuItem value={"Admin"}>Admin</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="mt-10">
        <Button variant="contained" className="btn-submit" onClick={save}>
          Save
        </Button>
      </div>
    </>
  );
}

export default NewUser;
