import {
  MdHistory,
  MdFormatListBulleted,
  MdAccountCircle,
  MdAdd,
  MdDone,
  MdExitToApp,
  MdList,
  MdFormatListNumbered,
} from "react-icons/md";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../Hooks/UserContext";
import React, { useContext } from "react";
import { logoutUser } from "../Utils/API_fetching";
import { USER_ROLE, APPROVAL_ROLE, LICENSER_ROLE } from "../Utils/UserRoles";
function Main() {
  const { user, setUser } = useContext(UserContext);
  let history = useHistory();
  function logout() {
    logoutUser()
      .then((res) => {
        setUser(null);
        localStorage.removeItem("token");
        toast.success("Log out successfully");
        history.push("/login");
      })
      .catch((e) => console.log(e));
  }
  return (
    <>
      {user.role}

      <div className="mt-10"></div>
      <div className="main-nav flex">
        {/* IS NOT USER OR APPROVAL  */}
        {user.role !== APPROVAL_ROLE && (
          <Link to="add" className="link">
            <div className="col-4 nav-hover">
              <div className="nav-card">
                <MdAdd size={45} />
                <h3 className="nav-text">Add Company</h3>
              </div>
            </div>
          </Link>
        )}
        {/* IS NOT USER OR APPROVAL  */}
        {user.role !== APPROVAL_ROLE && (
          <Link to="list" className="link">
            <div className="col-4 nav-hover">
              <div className="nav-card">
                <MdList size={45} />
                <h3 className="nav-text">Company List</h3>
              </div>
            </div>
          </Link>
        )}
        {/* IS NOT USER */}
        {user.role !== USER_ROLE && user.role !== LICENSER_ROLE && (
          <Link to="approve" className="link">
            <div className="col-4 nav-hover">
              <div className="nav-card">
                <MdDone size={45} color={"green"} />
                <h3 className="nav-text">Approve Company</h3>
              </div>
            </div>
          </Link>
        )}
        {/* IS NOT USER OR APPROVAL  */}
        {user.role !== USER_ROLE && user.role !== APPROVAL_ROLE && (
          <Link to="report" className="link">
            <div className="col-4 nav-hover">
              <div className="nav-card">
                <MdFormatListBulleted size={45} />
                <h3 className="nav-text">Report</h3>
              </div>
            </div>
          </Link>
        )}
        {user.role !== USER_ROLE &&
          user.role !== APPROVAL_ROLE &&
          user.role !== LICENSER_ROLE && (
            <Link to="category" className="link">
              <div className="col-4 nav-hover">
                <div className="nav-card">
                  <MdFormatListNumbered size={45} />
                  <h3 className="nav-text">Categories</h3>
                </div>
              </div>
            </Link>
          )}
        {/* IS NOT USER OR APPROVAL OR LICENSER */}
        {user.role !== USER_ROLE &&
          user.role !== APPROVAL_ROLE &&
          user.role !== LICENSER_ROLE && (
            <Link to="logs" className="link">
              <div className="col-4 nav-hover">
                <div className="nav-card">
                  <MdHistory size={45} />
                  <h3 className="nav-text">User Logs</h3>
                </div>
              </div>
            </Link>
          )}
        {/* ANYONE */}
        <Link to="settings" className="link">
          <div className="col-4 nav-hover">
            <div className="nav-card">
              <MdAccountCircle size={45} />
              <h3 className="nav-text">Account Settings</h3>
            </div>
          </div>
        </Link>

        <div className="col-4 nav-hover" onClick={() => logout()}>
          <div className="nav-card">
            <MdExitToApp size={45} color={"red"} />
            <h3 className="nav-text">Log Out</h3>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
