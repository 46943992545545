import BreadCrumbs from "./BreadCrumbs";
import Pagination from "./Pagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { MdBlock } from "react-icons/md";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "./Loading";
import { getLogs } from "../Utils/API_fetching";
import ReactPaginate from "react-paginate";
function Logs() {
  const [logs, setLogs] = useState();
  const [paging, setPaging] = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    getLogs(currentPage).then((res) => {
      setLogs(res.data.logs);
      setPaging(res.data.paging);
      setLoading(false);
    });
  }, [currentPage]);

  // function changePage(page) {
  //   setCurrentPage(page);
  // }
  const handlePageClick = (data) => {
    let selected = data.selected;
    setCurrentPage(selected + 1);
  };
  return (
    <div>
      <BreadCrumbs current={"User Logs"} />
      {loading ? <Loading /> : render()}
    </div>
  );

  function render() {
    return (
      <>
        <TableContainer component={Paper} className="list-table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>User Name</TableCell>
                <TableCell align="right">Action</TableCell>
                <TableCell align="right">Description</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Suspend User</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log) => (
                <TableRow key={log._id}>
                  <TableCell component="th" scope="row">
                    {log.user.email}
                  </TableCell>
                  <TableCell align="right">
                    <b className={log.action}>{log.action}</b>
                  </TableCell>
                  <TableCell align="right">{log.description}</TableCell>

                  <TableCell align="right">
                    {log.createdAt.split("T")[0]}
                  </TableCell>

                  <TableCell align="right">
                    <Link to="/view" className="link">
                      <MdBlock color={"red"} size={20} />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {paging.pages > 1 && (
          <ReactPaginate
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={paging.pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        )}
        {/* {paging.pages > 1 && (
          <Pagination paging={paging} changePage={changePage} />
        )} */}
      </>
    );
  }
}

export default Logs;
