import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

function stepThree({ change, info }) {
  return (
    <div className="add-card">
      <center>Chairman / Director / Representative</center>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Full Name"
            className="input-50"
            size="small"
            value={info[2].fullName}
            onChange={(e) => change(2, e.target.value, "fullName")}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Nationality"
            className="input-50"
            size="small"
            value={info[2].nationality}
            onChange={(e) => change(2, e.target.value, "nationality")}
          />
        </div>
      </div>

      <div className="flex text-area">
        <div className="text-50">
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">
              Legal Document
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={info[2].legalDocument}
              onChange={(e) => change(2, e.target.value, "legalDocument")}
              className="input-100"
              style={{ width: "100%" }}
            >
              <MenuItem value={"Passport"}>Passport</MenuItem>
              <MenuItem value={"National ID"}>National ID</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="text-50">
          <TextField
            id="standard-basic"
            label="Number"
            className="input-50"
            size="small"
            value={info[2].number}
            onChange={(e) => change(2, e.target.value, "number")}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-50">
          <TextField
            id="date"
            label="Date of birth"
            type="date"
            className="input-50"
            value={info[2].dateOfBirth}
            onChange={(e) => change(2, e.target.value, "dateOfBirth")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="text-50">
          <TextField
            id="standard-basic"
            label="Citizenship"
            className="input-50"
            size="small"
            value={info[2].citizenship}
            onChange={(e) => change(2, e.target.value, "citizenship")}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-50">
          <TextField
            id="standard-basic"
            label="Address"
            className="input-50"
            size="small"
            value={info[2].address}
            onChange={(e) => change(2, e.target.value, "address")}
          />
        </div>
        <div className="text-50">
          <TextField
            id="standard-basic"
            label="Tell"
            className="input-50"
            size="small"
            value={info[2].tell}
            onChange={(e) => change(2, e.target.value, "tell")}
          />
        </div>
      </div>
    </div>
  );
}

export default stepThree;
