import {
  BrowserRouter as Router,
  useParams,
  Link,
  useHistory,
} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import {
  findCompany,
  createLicense,
  deleteACompany,
} from "../Utils/API_fetching";
import Details from "./companyDetails";
import { useEffect, useState, useContext } from "react";
import Avatar from "@material-ui/core/Avatar";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import BreadCrumbs from "./BreadCrumbs";
import { MdModeEdit, MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import Loading from "./Loading";
import { USER_ROLE, APPROVAL_ROLE, LICENSER_ROLE } from "../Utils/UserRoles";
import { UserContext } from "../Hooks/UserContext";
import { addYears } from "date-fns";
function View() {
  const { user, setUser } = useContext(UserContext);
  let { slug } = useParams();
  let history = useHistory();
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [deleModal, setDeleModal] = useState(false);
  const [acronym, setAcronym] = useState("");
  const [issueDate, setIssueDate] = useState(new Date());
  const [expireDate, setExpireDate] = useState(addYears(new Date(), 1));
  async function fetch() {
    try {
      const data = await findCompany(slug);
      var str = data.data.company.companyName;
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      var acronym = matches.join(""); // JSON
      setAcronym(acronym);
      setCompany(data.data.company);
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
      history.push("/list");
    }
  }

  useEffect(() => {
    // var d = new Date();
    // var year = d.getFullYear();
    // var month = d.getMonth();
    // var day = d.getDate();

    // var fulldate = new Date(year + 1, month, day);

    // var toDate = fulldate.toISOString().slice(0, 10);
    // setExpire(toDate);
    fetch();
  }, [loading]);

  async function makeLicense() {
    try {
      const body = { issueDate, expireDate };
      const res = await createLicense(slug, body);
      toast.success("Company License Created");
      history.push(`/license/${slug}/${res.data.licenseNo}`);
    } catch (e) {
      toast.error(
        "Oops there was an error creating the license. Please try again"
      );
    }
  }

  function deleteCompany() {
    deleteACompany(company._id)
      .then((res) => {
        toast.success("Company deleted successfully");
        history.push("/list");
      })
      .catch((e) => {
        toast.error(e.response.data.message);
        history.push("/");
      });
  }

  return (
    <>
      {loading === true ? <Loading /> : info()}
      {modal && modalDiv()}
      {deleModal && deleteModal()}
    </>
  );

  function info() {
    return (
      <>
        <BreadCrumbs current={"View Company"} />
        <div className="company-info flex">
          <div className="left-info">
            <div className="main-info">
              <Link to={`/edit/${company.slug}`}>
                <button className="btn-edit hover">
                  <MdModeEdit />
                </button>
              </Link>
              <Avatar
                style={{
                  background: "orange",
                  margin: "10px auto",
                  width: "80px",
                  height: "80px",
                }}
              >
                {acronym}
              </Avatar>

              <h3>{company.companyName}</h3>
              <div className="left-text">
                <p className="info">
                  <b>HeadQuaters:</b>
                  <br />
                  {company.headQuaters}
                </p>
                <p className="info">
                  <b>Local Address:</b>
                  <br />
                  {company.localAddress}
                  <br />
                </p>
              </div>
            </div>
            {company.details !== undefined && (
              <div className="main-info">
                <h5>Company Licenses</h5>
                <div style={{ display: "inline" }}>
                  {company.license !== undefined && (
                    <Link
                      to={`/license/${company.slug}/${company.license.licenseNo}`}
                      className="link"
                    >
                      {new Date().toISOString() > company.license.expireAt ? (
                        <Button className="red">
                          #{company.license.licenseNo}
                        </Button>
                      ) : (
                        <Button className="green">
                          #{company.license.licenseNo}
                        </Button>
                      )}
                    </Link>
                  )}
                  {/*  USER AND APPROVAL CANT CREATE LICENSE */}
                  {user.role === USER_ROLE || user.role === APPROVAL_ROLE ? (
                    <>
                      {company.license === undefined && (
                        <small>This company has no license</small>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {user.role !== USER_ROLE && user.role !== APPROVAL_ROLE && (
                    <>
                      {company.license === undefined && (
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setModal(!modal)}
                          >
                            Create License
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}

            {/* USER AND APPROVAL CANT DELETE A COMPANY */}
            {user.role !== USER_ROLE &&
              user.role !== APPROVAL_ROLE &&
              user.role !== LICENSER_ROLE && (
                <div className="main-info">
                  <div style={{ display: "inline" }}>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        className="red"
                        onClick={() => setDeleModal(!modal)}
                      >
                        Delete Company
                      </Button>
                    </div>
                  </div>
                </div>
              )}
          </div>

          <div className="company-details">
            Company status:
            {company.approved === false && (
              <button className="btn-danger"> Not Approved</button>
            )}
            {company.approved === true && (
              <button className="btn-complete"> Approved</button>
            )}
            <div className="full-details">
              {company.details !== undefined && (
                <Link to={`/registration/${company.slug}/edit`}>
                  <button className="btn-edit hover">
                    <MdModeEdit />
                  </button>
                </Link>
              )}

              {company.details === undefined ? (
                <center>
                  No information to show company must be apporved or
                  registration must be completed
                </center>
              ) : (
                <Details company={company} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
  function modalDiv() {
    const handleDateChange = (date) => {
      setIssueDate(new Date(date));
      setExpireDate(addYears(new Date(date), 1));
    };
    return (
      <div className="info-modal">
        <div className="info-modal-card">
          <button className="info-close" onClick={() => setModal(!modal)}>
            <MdClose />
          </button>
          <h4>License Creation</h4>
          <br />
          <p>
            Are you sure you want to create a license for{" "}
            <b>{company.companyName}</b>?
          </p>
          <b />
          <div>
            <TextField
              id="date"
              label="Rewnewed Date"
              type="date"
              defaultValue={issueDate.toISOString().split("T")[0]}
              style={{ width: 300 }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleDateChange(e.target.value)}
            />
            <TextField
              id="date"
              label="Expire Date"
              type="date"
              defaultValue={expireDate.toISOString().split("T")[0]}
              value={expireDate.toISOString().split("T")[0]}
              disabled={true}
              style={{ width: 300 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div style={{ paddingTop: 20 }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 10 }}
              onClick={makeLicense}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setModal(!modal)}
            >
              No
            </Button>
          </div>
        </div>
      </div>
    );
  }
  function deleteModal() {
    return (
      <div className="info-modal" onClick={() => setDeleModal(!deleModal)}>
        <div className="info-modal-card">
          <h4 style={{ color: "red", fontWeight: "bold" }}>Warning!</h4>
          <br />
          <p>
            Are you sure you want to delete company <b>{company.companyName}</b>
            ?
          </p>
          <br />
          <small>
            <b>Note:</b> All data related to this company will be deleted. Once
            data is delete there is no way to retieve it again.
          </small>
          <b />
          <div style={{ paddingTop: 20 }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 10 }}
              onClick={deleteCompany}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setDeleModal(!deleModal)}
            >
              No
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default View;
