import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
function stepNine({ info, change }) {
  return (
    <div className="add-card">
      <center>Application Information</center>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Applicants position"
            className="input-50"
            size="small"
            value={info[8].position}
            onChange={(e) => change(8, e.target.value, "position")}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Applicants name"
            className="input-50"
            size="small"
            value={info[8].name}
            onChange={(e) => change(8, e.target.value, "name")}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Nationality"
            className="input-50"
            size="small"
            value={info[8].nationality}
            onChange={(e) => change(8, e.target.value, "nationality")}
          />
        </div>
        <div className="text-100">
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">
              Legal Document
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={info[8].legalDocument}
              onChange={(e) => change(8, e.target.value, "legalDocument")}
              className="input-100"
              style={{ width: "100%" }}
            >
              <MenuItem value={"Passport"}>Passport</MenuItem>
              <MenuItem value={"National ID"}>National ID</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="date"
            label="Date of Birth"
            type="date"
            className="input-50"
            InputLabelProps={{
              shrink: true,
            }}
            value={info[8].dateOfBirth}
            onChange={(e) => change(8, e.target.value, "dateOfBirth")}
          />
        </div>
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="address"
            className="input-50"
            size="small"
            value={info[8].address}
            onChange={(e) => change(8, e.target.value, "address")}
          />
        </div>
      </div>
      <div className="flex text-area">
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Applicants Phone"
            className="input-50"
            size="small"
            value={info[8].phone}
            onChange={(e) => change(8, e.target.value, "phone")}
          />
        </div>
        <div className="text-100">
          <TextField
            id="standard-basic"
            label="Applicants Email"
            className="input-50"
            size="small"
            value={info[8].email}
            onChange={(e) => change(8, e.target.value, "email")}
          />
        </div>
      </div>
    </div>
  );
}

export default stepNine;
