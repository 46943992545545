import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { MdSearch } from "react-icons/md";
function Search({ find }) {
  return (
    <div className="search center flex">
      <TextField
        id="input-with-icon-textfield"
        label="Search for company"
        className="search-input"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdSearch />
            </InputAdornment>
          ),
        }}
        onChange={(e) => find(e.target.value)}
      />
    </div>
  );
}

export default Search;
